var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SidebarLeft',{class:{'sidebar-left-show': _vm.$store.state.sidebars.leftSidebar}},[_c('FilterEventsForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.sidebars.leftSidebar),expression:"$store.state.sidebars.leftSidebar"}],ref:"filter"})],1),_c('TableContainer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isChart),expression:"!isChart"}],ref:"table-container",attrs:{"columns":_vm.tableData,"counter-filter":_vm.$store.state.events.searchCount,"is-archive":_vm.$can('edit', 'event-read-archive'),"is-archiving":_vm.$can('edit', 'event-trash'),"is-loading-items":_vm.$store.state.other.isLoadingItems,"is-loading-new-item":_vm.$store.state.other.isLoadingNewItem,"is-remove-from-archive":_vm.$can('edit', 'event-delete'),"rows":_vm.$store.state.events.elements,"sorting-fields":_vm.sortingFields,"name":"events","title":"Мероприятия"},on:{"update-data":_vm.getElements,"on-selected":_vm.selectItem,"delete-item":_vm.deleteItem,"archive-items":_vm.archiveItems,"return-from-archive":_vm.returnFromArchive,"on-change-archive":() => {
        _vm.$store.commit('events/changePage', 1)
        if (_vm.$route.name !== 'events') _vm.$router.push({name: 'events'})
      },"next-page":_vm.loadNewData},scopedSlots:_vm._u([{key:"head-buttons-after",fn:function(){return [(_vm.$store.state.events.selectedItemsForDelete.length === 1)?_c('zem-link',{staticClass:"tw-gap-x-1.5",on:{"click":_vm.onCopyEvent}},[_c('img',{attrs:{"src":require('@/assets/icons/copy.svg'),"alt":"","width":"16"}}),_c('span',{staticClass:"mobile-d-none"},[_vm._v(_vm._s(_vm.$t('Copy')))])]):_vm._e(),(_vm.$can('edit', 'event-create'))?_c('ZemButton',{attrs:{"disabled":_vm.isLoadingItem,"loading":_vm.isLoadingItem,"icon":""},on:{"click":_vm.onCreateItem}},[(!_vm.isLoadingItem)?_c('img',{staticClass:"tw-mb-[1px]",attrs:{"src":require('@/assets/icons/plus.svg'),"alt":""}}):_vm._e(),_c('span',{staticClass:"mobile-d-none"},[_vm._v("Мероприятие")])]):_vm._e()]},proxy:true}])}),_c('EventsChart',{ref:"eventsChart",class:!_vm.isChart ? 'tw-hidden' : 'tw-flex tw-flex-col',on:{"on-selected":_vm.selectItem,"on-create":_vm.onCreateItem},scopedSlots:_vm._u([{key:"head-buttons",fn:function(){return [(_vm.$can('edit', 'event-create'))?_c('ZemButton',{attrs:{"disabled":_vm.isLoadingItem,"loading":_vm.isLoadingItem,"icon":""},on:{"click":_vm.onCreateItem}},[(!_vm.isLoadingItem)?_c('img',{staticClass:"tw-mb-[1px]",attrs:{"src":require('@/assets/icons/plus.svg'),"alt":""}}):_vm._e(),_c('span',{staticClass:"mobile-d-none"},[_vm._v("Мероприятие")])]):_vm._e()]},proxy:true}])}),_c('SidebarRight',{class:{
      'sidebar-right--show tw-w-[500px] tw-min-w-[500px] max-md:tw-w-full max-md:tw-min-w-full max-md:tw-h-[calc(100dvh-45px)] max-md:tw-overflow-auto':
        _vm.sidebars.rightSidebar,
    }},[_c('EventDetails',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isAccessUpdate),expression:"!isAccessUpdate"}],ref:"event-details"}),_c('EventChange',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAccessUpdate),expression:"isAccessUpdate"}],ref:"event-change",on:{"close":function($event){_vm.isNew = false},"on-change":function($event){['chart', 'chart-orders'].includes(_vm.$route.query.view) ? _vm.onActiveChart() : _vm.onActiveTable()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }